import React, { useState } from "react";
import { graphql } from "gatsby";
import { StructuredText } from "react-datocms";
import {
  renderRule,
  isHeading,
  isParagraph,
  isList,
} from "datocms-structured-text-utils";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { getImage, StaticImage } from "gatsby-plugin-image";

import Paragraph from "@components/Paragraph";
import Title from "@components/Title";
import Layout from "@hoc/Layout";
import Button from "@components/Button";
import Seo from "@components/Seo";
import Link from "@components/Link";
import LatestNews from "@components/LatestNews";
import AuthorCard from "@components/AuthorCard";
import Lightbox from "@components/Lightbox";
import {
  StructuredImage,
  StructuredGallery,
  StructuredLineBreak,
  StructuredImageText,
  StructuredQuote,
  StructuredList,
  StructuredVideo,
  StructuredNumericalList,
} from "@components/Structured";
import Divider from "@components/Divider";
import SVG from "react-inlinesvg";
import Icon from "@components/Icon";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function NewsPost({ pageContext, data }) {
  const background = getImage(data.blog.heroImage);
  const bg = convertToBgImage(background);
  const newsIcon = pageContext?.global?.newsIcon?.url;
  const [copied, setCopied] = useState(false);
  const _href = typeof window !== "undefined" ? window.location.href : "";

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCopyClick = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 650);
  };

  return (
    <Layout locale={pageContext.locale}>
      <Lightbox />
      <Seo {...{ ...data.blog.seoMeta, lang: pageContext.locale }} />
      <BackgroundImage Tag="section" {...bg} className="">
        <div className="bg-black opacity-40 h-full w-full absolute top-0 left-0 z-10" />
        <div className="w-11/12 mx-auto h-full flex items-baseline py-28 lg:pt-80 lg:pb-40 flex-col relative z-20">
          <div className="flex items-center justify-start">
            {newsIcon ? (
              <div className="h-14 w-14 mr-4 bg-primary relative">
                <SVG
                  src={newsIcon}
                  className="w-5 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
                />
              </div>
            ) : null}
            <div className="inline-flex items-center justify-center h-14 bg-primary text-white px-6 font-display ru-countach-font tracking-wide text-3xl py-2 uppercase text-center">
              {data.blog?.category?.categoryName}
            </div>
          </div>
          <span className="my-6 font-body uppercase text-white tracking-widest">
            {data.blog.meta.firstPublishedAt}
          </span>
          <Title
            {...{
              color: "white",
              type: 1,
              font: "body",
              className:
                "mt-0 font-bold w-full md:w-2/3 text-4xl lg:text-6xl xl:text-7xl",
            }}
          >
            {pageContext.newsTitle}
          </Title>
        </div>
        <Divider
          {...{
            name: "texture1",
            className: `absolute transform -scale-y-100 -bottom-1 left-0 z-50`,
            fill: "fill-black",
          }}
        />
      </BackgroundImage>
      <section
        {...{
          className: "relative overflow-hidden",
        }}
      >
        <div className="bg-black text-white py-20 relative text-center md:text-left">
          <div className="w-11/12 mx-auto md:grid md:grid-cols-12 md:gap-12 xl:max-w-screen-2xl relative z-30">
            <div className="col-span-full md:col-span-3 lg:col-start-1">
              <div className="mb-12">
                <Title
                  {...{
                    type: 2,
                    color: "white",
                    className:
                      "mb-8 !text-3xl !lg:text-3xl !xl:text-4xl lg:mt-0",
                  }}
                >
                  Share on <br /> social media
                </Title>

                <div className="flex flex-wrap items-center justify-center md:justify-start max-w-[200px] mx-auto md:-mx-2 -m-2">
                  <div className="w-1/3 p-2">
                    <a
                      href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                        _href
                      )}`}
                      target="_blank"
                      rel="noopener nofollow noreferrer"
                      className="group inline-block md:block w-8 h-8 relative"
                    >
                      <Icon
                        name="twitter"
                        className="block w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-white group-hover:fill-primary transition"
                      />
                    </a>
                  </div>
                  <div className="w-1/3 p-2">
                    <a
                      href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                        _href
                      )}`}
                      target="_blank"
                      rel="noopener nofollow noreferrer"
                      className="group inline-block md:block w-8 h-8 relative"
                    >
                      <Icon
                        name="facebook"
                        className="block w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white group-hover:text-primary transition"
                      />
                    </a>
                  </div>
                  <div className="w-1/3 p-2 relative">
                    <span
                      className={`flex items-center h-8 px-2 -ml-4 absolute top-1/2 left-full transform -translate-y-1/2 bg-black text-white border border-white shadow-lg pointer-evens-none select-none ${
                        copied ? "opacity-100 visible" : "opacity-0 invisible"
                      } transition-all`}
                    >
                      Copied!
                    </span>
                    <CopyToClipboard text={_href} onCopy={handleCopyClick}>
                      <span className="group inline-block md:block w-8 h-8 relative cursor-pointer">
                        <Icon
                          name="copy"
                          className="block w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white group-hover:text-primary transition"
                        />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>

                {data.blog?.newsAuthor?.authorName && (
                  <>
                    <Title
                      {...{
                        type: 2,
                        color: "white",
                        className:
                          "mt-8 mb-2 !text-3xl !lg:text-3xl !xl:text-4xl",
                      }}
                    >
                      Author
                    </Title>
                    <Paragraph
                      {...{
                        className: "uppercase text-xl",
                        color: "white",
                      }}
                    >
                      BY {data.blog.newsAuthor.authorName}
                    </Paragraph>
                  </>
                )}
              </div>
            </div>

            <div className="col-span-8 max-w-3xl">
              <StructuredText
                data={data.blog.blackBarContent}
                customRules={[
                  renderRule(
                    isHeading,
                    ({ node, adapter: { renderNode }, children, key }) => {
                      return (
                        <Title
                          {...{
                            key,
                            color: "white",
                            type: node.level,
                            className: `mt-0 text-5xl lg:text-6xl`,
                          }}
                        >
                          {children}
                        </Title>
                      );
                    }
                  ),
                  renderRule(
                    isParagraph,
                    ({ node, adapter: { renderNode }, children, key }) => {
                      return (
                        <Paragraph
                          {...{
                            key,
                            color: "white",
                          }}
                        >
                          {children}
                        </Paragraph>
                      );
                    }
                  ),
                  renderRule(isList, ({ children }) => {
                    return (
                      <div>
                        <ul className="custom-list">{children}</ul>
                      </div>
                    );
                  }),
                ]}
              />
            </div>
          </div>

          <StaticImage
            src="../assets/geolines/bkg-black-lines-left@2x.png"
            className="!absolute left-0 -bottom-10 max-w-[650px] xl:max-w-[725px] 2xl:max-w-[750px] z-20 select-none pointer-events-none"
            width={650}
          />
        </div>
        <div className="py-20 pt-28 relative z-30">
          <Divider
            {...{
              name: "texture2",
              className: `z-50 min-w-[850px] absolute top-0 left-0`,
              fill: "fill-black",
            }}
          />
          <div className="pb-20 mb-2 border-b-2 border-offwhite border-opacity-30 border-dashed">
            <StructuredText
              data={data.blog.newsContent}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsStructuredImage":
                    return (
                      <StructuredImage
                        {...{
                          image: record.image,
                          alt: record.image.alt,
                          className: "max-w-screen-lg mx-auto w-11/12 mb-8",
                        }}
                      />
                    );
                  case "DatoCmsArticleLinebreak":
                    return (
                      <StructuredLineBreak
                        {...{
                          className: "max-w-screen-2xl mx-auto w-11/12 mb-8",
                        }}
                      />
                    );
                  case "DatoCmsStructuredGallery":
                    return (
                      <StructuredGallery
                        {...{
                          images: record.images,
                          className: "mb-8",
                        }}
                      />
                    );
                  case "DatoCmsStructuredVideo":
                    return (
                      <StructuredVideo
                        {...{
                          video: record.video,
                          className: "max-w-screen-lg mx-auto w-11/12 mb-8",
                        }}
                      />
                    );
                  case "DatoCmsStructuredImageText":
                    return (
                      <StructuredImageText
                        {...{
                          image: record.image,
                          alt: record.image.alt,
                          body: record.body,
                          reverse: record.reverseOrder,
                          className: "max-w-screen-lg mb-8 mx-auto w-11/12",
                        }}
                      />
                    );
                  case "DatoCmsStructuredQuote":
                    return (
                      <StructuredQuote
                        {...{
                          quote: record.quote,
                          className: "max-w-3xl mb-8 mx-auto w-11/12",
                        }}
                      />
                    );
                  default:
                    return null;
                }
              }}
              customRules={[
                renderRule(isHeading, ({ node, children, key }) => {
                  return (
                    <Title
                      {...{
                        key,
                        color: node.level === 1 ? "black" : "primary",
                        type: node.level,
                        className: `max-w-3xl w-11/12 mx-auto`,
                      }}
                    >
                      {children}
                    </Title>
                  );
                }),
                renderRule(isParagraph, ({ node, children, key }) => {
                  return (
                    <Paragraph
                      {...{
                        key,
                        color: "black",
                        className: "mb-8 max-w-3xl mx-auto w-11/12",
                      }}
                    >
                      {children}
                    </Paragraph>
                  );
                }),
                renderRule(isList, ({ node, children, adapter, key }) => {
                  switch (node.style) {
                    case "bulleted":
                      return (
                        <StructuredList
                          {...{
                            node,
                            adapter,
                            key,
                            className: "max-w-3xl w-11/12 mx-auto mb-8",
                          }}
                        >
                          {children}
                        </StructuredList>
                      );
                    case "numbered":
                      return (
                        <StructuredNumericalList
                          {...{
                            node,
                            adapter,
                            key,
                            className: "max-w-3xl w-11/12 mx-auto",
                          }}
                        >
                          {children}
                        </StructuredNumericalList>
                      );
                  }
                }),
              ]}
            />

            {data.blog.newsAuthor && (
              <AuthorCard
                {...{
                  name: data.blog.newsAuthor.authorName,
                  email: data.blog.newsAuthor.authorEmail,
                  socials: data.blog.newsAuthor.socialMedia,
                  image: data.blog.newsAuthor.authorImage,
                  blurb: data.blog.newsAuthor.authorBlurb,
                  className: "lg:max-w-screen-lg mx-auto",
                }}
              />
            )}
          </div>
          <div className="max-w-screen-xl w-11/12 mx-auto flex flex-col items-center justify-center gap-8 md:flex-row md:justify-between my-8 xl:my-16">
            <Link
              {...{
                to: "/",
                type: "button",
                color: "white",
              }}
            >
              Back Home
            </Link>

            <Button
              {...{
                clickHandler: () => scrollTop(),
                className: "!text-3xl",
              }}
            >
              Back To Top
            </Button>
          </div>
        </div>

        <StaticImage
          src="../assets/geolines/bkg-white-lines-left@2x.png"
          className="!absolute left-0 -bottom-28 max-w-[750px] 2xl:max-w-[950px] z-20 opacity-75"
          width={650}
          alt="Geolines Graphic"
        />

        <StaticImage
          src="../assets/geolines/bkg-white-lines-right@2x.png"
          className="!absolute hidden lg:block right-0 -bottom-28 max-w-[400px] xl:max-w-[500px] z-20 opacity-75"
          width={450}
          alt="Geolines Graphic"
        />

        <Divider
          {...{
            name: "texture1",
            className: `absolute transform -scale-y-100 -bottom-1 left-0 z-50`,
            fill: "fill-light",
          }}
        />
      </section>
      <section className="overflow-hidden bg-light relative">
        <LatestNews
          {...{
            block: {
              title: "Latest Articles",
              posts: data.posts.nodes,
            },
            className: "bg-light py-20 pb-28",
            locale: pageContext.locale,
          }}
        />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query getAnglerPost($id: String, $locale: String) {
    blog: datoCmsNews(id: { eq: $id }, locale: { eq: $locale }) {
      id
      locale
      locations
      pageSlug
      newsTitle
      excerpt
      model {
        name
      }
      meta {
        firstPublishedAt(formatString: "DD/MM/YYYY")
      }
      seoMeta {
        description
        title
        twitterCard
        image {
          url
        }
      }
      seoMetaTags {
        tags
      }
      category {
        categoryName
      }
      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
        alt
      }
      blackBarContent {
        value
        blocks {
          ... on DatoCmsArticleLinebreak {
            __typename
            id: originalId
          }
          ... on DatoCmsStructuredQuote {
            __typename
            id: originalId
            quote
          }
        }
      }
      newsContent {
        value
        blocks {
          ... on DatoCmsArticleLinebreak {
            __typename
            id: originalId
          }
          ... on DatoCmsStructuredImage {
            __typename
            id: originalId
            image {
              gatsbyImageData(width: 900, placeholder: DOMINANT_COLOR)
              alt
              title
            }
          }
          ... on DatoCmsStructuredGallery {
            __typename
            id: originalId
            images {
              gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR)
              full: gatsbyImageData(width: 1200, placeholder: DOMINANT_COLOR)
              alt
            }
          }
          ... on DatoCmsStructuredVideo {
            __typename
            id: originalId
            video {
              providerUid
            }
          }
          ... on DatoCmsStructuredQuote {
            __typename
            id: originalId
            quote
          }
          ... on DatoCmsStructuredImageText {
            __typename
            id: originalId
            reverseOrder
            body {
              value
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
              alt
              title
            }
          }
        }
      }
      newsAuthor {
        authorName
        twitterHandle
        socialMedia {
          socialMediaType
          socialLink
        }
        authorImage {
          gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR)
          alt
        }
        authorEmail
        authorBlurb
      }
    }

    posts: allDatoCmsNews(
      filter: { 
        locale: { eq: $locale },
        id: { ne: $id }
      }
      sort: { order: DESC, fields: meta___createdAt }
      limit: 3
    ) {
      nodes {
        id
        meta {
          firstPublishedAt(formatString: "DD/MM/YYYY")
        }
        heroImage {
          gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR)
        }
        model {
          name
        }
        pageSlug
        newsTitle
        excerpt
      }
    }
  }
`;
